<template>
<div>
    <v-dialog v-model="$store.state.dialog2" width="400">
        <v-card flat class="card">
            <v-toolbar outlined flat color="grey lighten-4" light>
                <v-toolbar-title>{{$store.state.itemDetails.dialogType == 'add' ? 'إضافة' : 'تعديل'}} {{this.$route.meta.single}}</v-toolbar-title>
                <v-spacer />
                <v-btn @click="close()" icon>
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-form @submit.prevent="submit()" ref="form" v-model="valid">
                <v-container>
                    <v-card outlined>
                        <v-container>

                            <v-row>
                                <v-col cols="12" md="12">
                                    <h3 class="mb-2">كلمة المرور الجديدة<span class="required">*</span></h3>
                                    <v-text-field v-model="item.newPassword" placeholder="****" :rules="[$global.state.required()]" required filled outlined hide-details="auto" />
                                </v-col>
                            </v-row>

                        </v-container>
                    </v-card>
                    <v-col cols="12" md="12">
                        <v-row class="mt-2">
                            <v-btn type="submit" :disabled="!valid" :loading="$global.state.loading" x-large color="primary" class="btn">حفظ</v-btn>
                            <v-btn @click="close()" x-large color="error" class="mr-2 btn" outlined>إلغاء</v-btn>
                        </v-row>
                    </v-col>
                </v-container>
            </v-form>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {

    data() {
        return {
            item: {},
            valid: true,
        }
    },

    created() {
        this.reset()

        this.$eventBus.$on('fill-fields', () => {
            // console.log(this.$store.state.itemDetails)

        })
    },

    methods: {

        reset() {
            this.item = {
                "newPassword": "",
                "confirmPassword": ""
            }
        },

        submit() {            
            if (this.$store.state.itemDetails.dialogType == 'add') {
                this.addItem()
            } else {
                this.editItem()
            }
        },

        addItem() {
            this.$global.state.loading = true
            this.$http.put(`User/changePasswordByAdmin/${this.$store.state.itemDetails.id}`, this.item).then(res => {
                this.$service.success('تم التعديل بنجاح')
                this.$eventBus.$emit(`${this.$route.path}`)
                this.close()
            }).catch(err => {
                this.$service.faild(err.response.data.message)
            }).finally(() => this.$global.state.loading = false)
        },

        editItem() {
            this.$global.state.loading = true
            this.item.confirmPassword = this.item.newPassword
            this.$http.put(`User/changePasswordByAdmin/${this.$store.state.itemDetails.id}`, this.item).then(res => {
                this.close()
                this.$service.success('تم التعديل بنجاح')
                this.$eventBus.$emit(`${this.$route.path}`)
            }).catch(err => {
                this.$service.faild(err.response.data.message)
            }).finally(() => this.$global.state.loading = false)
        },

        close() {
            this.$store.commit('setDialog2')
            this.reset()
            this.resetValidation()
            this.$store.commit('setItemDetails', {})
        },

        resetValidation() {
            this.$refs.form.resetValidation()
        },

    },
}
</script>
